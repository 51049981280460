<template>
  <UBanner>
    <article class="box">
      <RouterLink
        style="text-decoration: none; color: inherit;"
        to="/library"
      >
        <h1 class="page-title">
          {{ librarySettings.title }}
        </h1>
      </RouterLink>
      <VCard class="library__card">
        <VBreadcrumbs
          class="library-book__bread-crumbs"
          :items="breadCrumbs"
          divider=">"
        />
        <VContainer
          class="library__buttons-container"
        >
          <VBtn
            id="allBooks"
            :outlined="libraryTreeViewShow"
            color="primary"
            class="library-catalog__button"
            @click="libraryTreeViewShow = !libraryTreeViewShow"
          >
            <VIcon> {{ libraryTreeViewShow ? 'arrow_drop_down' : 'arrow_drop_up' }} </VIcon>
            {{ $t('$app.library_catalog') }}
          </VBtn>
        </VContainer>
        <VRow class="library__row-container">
          <VCol
            :class="{ 'library__tree-view': libraryTreeViewShow }"
            lg="3"
            xs="12"
          >
            <LibraryTreeView />
          </VCol>
          <VCol
            lg="9"
            xs="12"
          >
            <LibraryBook :book="book" />
          </VCol>
        </VRow>
      </VCard>
    </article>
  </UBanner>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import UBanner from '@components/UBanner.vue'
import LibraryBook from '@components/LibraryBook.vue'
import LibraryTreeView from '@components/LibraryTreeView.vue'
import { getLibraryNode } from '@/api/api'
import documentTitle from '@/mixins/documentTitle'

export default {
  name: 'TheLibraryBookPage',
  components: { LibraryTreeView, LibraryBook, UBanner },

  mixins: [documentTitle],

  data () {
    return {
      documentTitle: '$sdo.lc_menu_library.one',
      libraryTreeViewShow: true,
      select: '',
      search: '',
      book: {
        files: [],
        tags: [],
      },
    }
  },

  computed: {
    ...mapGetters({
      catalogs: getters.LIBRARY_CATALOGS,
      librarySettings: getters.LIBRARY_SETTINGS,
    }),

    breadCrumbs () {
      return [
        {
          text: this.$store.getters.LIBRARY_SETTINGS.title,
          disabled: false,
          exact: true,
          to: '/library'
        },
        {
          text: this.$t('$app.library_catalog'),
          disabled: false,
          exact: true,
          to: '/library/documents'
        },
        {
          text: this.book.catalog_title,
          disabled: false,
          exact: true,
          to: `/library/documents/${this.book.catalog_id}`
        },
        {
          text: this.book.title,
          disabled: true,
        },
      ]
    }
  },

  created () {
    this.getLibrarySettings()
    this.getCatalogs()

    getLibraryNode(this.$route.params.id).then(response => {
      this.book = response.node
      this.book.tags = (this.book.tags || response.tags || [])
      this.book.files = (this.book.files || response.files || [])
    })
  },

  updated () {
    this.pageScroll()
  },

  methods: {
    ...mapActions({
      getCatalogs: actions.GET_LIBRARY_CATALOGS,
      getLibrarySettings: actions.GET_LIBRARY_SETTINGS,
    }),

    pageScroll () {
      const scrollElement = document.querySelector('.library-catalog__button')
      if (this.$vuetify.breakpoint.smAndDown) {
        scrollElement.scrollIntoView()
      } else {
        window.scrollTo(0, 0)
      }
    },
  },
}
</script>

<style lang="scss">
.v-breadcrumbs {
  li {
    max-width: 30%;
    word-break: break-word;
  }
  li:last-child {
    max-width: 50%;
    word-break: break-word;
  }
}

.library__buttons-container {
  margin-left: 0;
}

.v-application {
  ul {
    padding: 18px 12px;
  }
}

.library-catalog__button {
  display: none;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .v-breadcrumbs {
    li {
      max-width: 100% !important;
      word-break: break-word;
    }
  }

  .library-catalog__button {
    display: inline;
    margin-right: 1rem;
  }

  .library__tree-view {
    display: none;
  }

  .library-book__container {
    display: flex;
    align-content: center;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .v-breadcrumbs {
    li {
      max-width: 100% !important;
      word-break: break-word;
    }
  }

  .library-catalog__button {
    display: inline;
    margin-right: 1rem;
  }

  .library__tree-view {
    display: none;
  }

  .library-book__container {
    display: flex;
    align-content: center;
  }
}
</style>
